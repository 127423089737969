import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ImageUtils from '../../../../utils/imageUtils';
import { Button } from '../../../../components';

import { selectVkycData } from '../../selectors';
import { getCaptureScreenshot } from '../../actions';
import { CAMERA_ORIENTATION } from '../../constants';

const FaceCaptureStep = (props) => {
    const { data, meetingId, setAllAnsweredYes } = props;

    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData, shallowEqual);

    const captureOnClick = () => {
        // to-do
        setAllAnsweredYes(true);
        const imageUrl = vkycData?.meetings?.[meetingId]?.screenshot?.imageUrl;
        const subStep = vkycData?.meetings?.[meetingId]?.stepNumber;
        if (imageUrl) {
            dispatch(getCaptureScreenshot({
                meetingId,
                screenKey: ['meetings', meetingId, 'stages',
                    [subStep], data?.stages[0]?.image_capture?.camera_type],
            }));
        }
        dispatch(getCaptureScreenshot({
            meetingId,
            screenKey: ['meetings', meetingId, 'stages',
                [subStep], data?.stages[0]?.image_capture?.camera_type],
        }));
    };

    return (
        <div>
            <div className='vkyc-container-title'>{data?.stages?.[0]?.image_capture?.instruction}</div>
            <div className='vkyc-image-container'>
                <div className='vkyc-image-placeholder'>
                    <ImageUtils
                        url={vkycData?.meetings?.[meetingId]?.stages?.[vkycData?.meetings?.[meetingId]?.stepNumber]?.
                            [CAMERA_ORIENTATION.front]?.imageUrl}
                        containerClass='vkyc-image'
                    />
                    <Button
                        v2
                        secondary
                        label={
                            // eslint-disable-next-line max-len
                            vkycData?.meetings?.[meetingId]?.stages?.[vkycData?.meetings?.[meetingId]?.stepNumber]?.[CAMERA_ORIENTATION.front]?.imageUrl
                                ? 'Retake'
                                : 'Capture'
                        }
                        extClasses='vkyc-image-btn__capture'
                        onClick={captureOnClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default FaceCaptureStep;
