import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import VideoStreamLayout from '../VideoStreamLayout';

import CurrentStage from './CurrentStage';
import BottomOptions from './BottomOptions';
import { selectMeetingLoadingState, selectMeetingId } from '../selectors';
import { Loader } from '../../../components';

const CallScreen = (props) => {
    const { stopRecording } = props;
    const [nextStepEnable, setNextStepEnable] = useState(false);
    const meetingId = useSelector(selectMeetingId, shallowEqual);
    const meetingLoadingState = useSelector(selectMeetingLoadingState(meetingId));

    return (
        <div className='vkyc-container'>
            <div className='vkyc-left-video-container'>

                <VideoStreamLayout />
            </div>
            <div className='vkyc-right-qna-container'>
                <div className='vkyc-qna-container'>
                    <CurrentStage stopRecording={stopRecording} setNextStepEnable={setNextStepEnable} />
                </div>
                <div className='vkyc-qna-line' />
                <BottomOptions nextStepEnable={nextStepEnable} setNextStepEnable={setNextStepEnable} stopRecording={stopRecording} />
            </div>
            <Loader visible={!!meetingLoadingState} />
        </div>
    );
};

export default CallScreen;
