/* eslint-disable react/jsx-props-no-spreading */
/**
 * @file Trnasction Table using ReactDataGrid
 * contains features like
 *      - virtualization
 *      - multi column searching and sorting
 *      - column reording
 *      - column pinning
 *      - adjustable column width
 */

import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import DataGrid, { SelectColumn, Row } from 'react-data-grid';
import classNames from 'classnames';
import 'react-data-grid/lib/styles.css';

import { shallowEqual, useSelector } from 'react-redux';
import { getCurrencyAmountFormat } from '../../../utils/currencyHelper';
import { debounce } from '../../../utils/helpers';
import { Button, Loader } from '../../../components';
import { isNilOrEmpty } from '../../../utils';
import { selectMetaInfo } from '../../../appGlobal/selectors';

import {
    CREDIT, DEBIT, FAILED, M2P, P2M, SUCCESS, COLORS,
} from '../constants';

import {
    ALL, COLUMN_MIN_WIDTH, COLUMN_WIDTH, COLWIDTH, FILTERS, ORDERS, PINS, SELECTEDROWS, TRANSACTION_REVIEW_V2_COLUMN_WIDTH,
    TRANSACTION_REVIEW_V2_FILTERS, TRANSACTION_REVIEW_V2_ORDER,
    TRANSACTION_REVIEW_V2_PINS, defaultPinnedColumns, TXN_DISPLAY_CATEGORY,
} from './constants';
import {
    clearPersistedData,
    getDateforTxnTable,
    getPersistedData, multiLevelFilter, multiLevelSort, persistData,
} from './utils';
import ColorFormatCell from './ColorFormatCell';
import HeaderFilter from './HeaderFilter';
import ClearDropDown from './ClearDropDown';
import './style.scss';
import ContextMenu from './ContextMenu';
import TransactionAnnotationModal from '../TransactionAnnotationModal';

// Color Codes dictionary
const colorCodesDict = {
    accounting_entry_type: {
        variant: 'contained',
        colors: {
            [DEBIT]: {
                value: COLORS.lightRed,
            },
            [CREDIT]: {
                value: COLORS.lightGreen,
            },
        },
    },
    other_actor_name: {
        variant: 'text',
        colors: (row) => {
            // enters when money_flow is either P2M or M2P
            if ([P2M, M2P].includes((row?.money_flow || '').toUpperCase())) {
                return { value: COLORS.blue };
            }
            return null;
        },
    },
    amount: {
        variant: 'contained',
        colors: (row) => {
            if (row.accounting_entry_type === DEBIT) {
                return { value: COLORS.lightRed };
            }
            if (row.accounting_entry_type === CREDIT) {
                return { value: COLORS.lightGreen };
            }
            return null;
        },
    },
    transaction_status: {
        variant: 'text',
        colors: {
            // status value Success and Failed
            [SUCCESS]: {
                value: COLORS.green,
            },
            [FAILED]: {
                value: COLORS.red,
            },
        },
    },
};

const DateRenderCell = (cellProps) => {
    const value = cellProps?.row[cellProps?.column?.key];
    if (value) {
        return getDateforTxnTable(value); // convert timestamp to date and time string
    }

    return null;
};

// function to enable drag hightlighter to select multiple rows via draging
const renderEditCell = (cellProps) => {
    const value = cellProps?.row[cellProps?.column?.key];
    return value || '';
};

// function to enable drag hightlighter to select multiple rows via draging
const renderRiskSignalCell = (cellProps) => {
    const value = cellProps?.row[cellProps?.column?.key];
    if (value && Array.isArray(value)) {
        return value.join(', '); // convert timestamp to date and time string
    }
    return value || '';
};

/**
 * createTransactionToProxyBalanceMap function create closing balance value to transaction map
 * if transaction type = 'CREDIT' => x amount has been added to user account.
 * if transaction type = 'DEBIT' => x amount has been removed from user account.
 *
 * @param {*} data : transactions list for transaction view table.
 * @returns updated list of transaction data with estimated balance value.
 */

const createTransactionToProxyBalanceMap = (data) => {
    data.sort((d1, d2) => d1.order_created_at - d2.order_created_at);
    let estBalance = 0;
    const estBalanceToCreateDateMap = {};
    data.forEach((txn) => {
        if (txn?.transaction_status === 'Success') {
            if (txn.accounting_entry_type === 'CREDIT') {
                estBalance += txn.amount;
            } if (txn.accounting_entry_type === 'DEBIT') {
                estBalance -= txn.amount;
            }
        }
        estBalanceToCreateDateMap[txn.transaction_id] = estBalance;
    });
    return estBalanceToCreateDateMap;
};

const renderRow = (key, rowProps) => (
    <Row
        {...rowProps}
        key={key}
        rowClass={(rowData) => {
            switch (rowData?.display_category) {
                case TXN_DISPLAY_CATEGORY.SELF_TRANSFER_DEBIT:
                    return 'row-self-transfer-debit';
                case TXN_DISPLAY_CATEGORY.SELF_TRANSFER_CREDIT:
                    return 'row-self-transfer-credit';
                default:
                    return '';
            }
        }}
    />
);

const TransactionTable = (props) => {
    const { data, fetching } = props;

    const [filters, setFilters] = useState(getPersistedData(TRANSACTION_REVIEW_V2_FILTERS));
    const [pinnedColumn, setPinnedColumn] = useState(() => new Set(getPersistedData(TRANSACTION_REVIEW_V2_PINS, defaultPinnedColumns)));
    const [columnWidths, setColumnWidths] = useState(getPersistedData(TRANSACTION_REVIEW_V2_COLUMN_WIDTH));
    const [isFullScreen, setFullScreen] = useState(false);
    const metaInfo = useSelector(selectMetaInfo(), shallowEqual);
    const isEstAmountFieldVisible = metaInfo?.riskOps?.estBalanceVisibility;
    const estimateBalanceToDateMap = React.useMemo(() => createTransactionToProxyBalanceMap(data), [data]);
    // used for re-rendering table on clear the column widths
    const [isClear, setClear] = useState(false);

    const label = !isFullScreen ? 'Full Screen' : 'Normal';
    const count = data.length; // data count
    const riskSignalRules = data.map((item) => item.signals).filter((item) => item) || [];

    const containerClasses = classNames('rdgrid-tt-c', {
        'rdgrid-tt-c-fs': isFullScreen,
    });

    // Disabling body scrollbar on fullscreen mode
    useEffect(() => {
        const root = document.body;
        if (isFullScreen) {
            root.style.overflow = 'hidden';
        } else {
            root.style.overflow = 'auto';
        }

        return () => {
            root.style.overflow = 'auto';
        };
    }, [isFullScreen]);

    const handleMode = () => {
        setFullScreen((pre) => !pre);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePin = (key) => (evt) => {
        evt.stopPropagation();
        const newPinned = new Set(pinnedColumn);
        if (newPinned.has(key)) {
            newPinned.delete(key);
        } else {
            newPinned.add(key);
        }

        const values = Array.from(newPinned);
        persistData(TRANSACTION_REVIEW_V2_PINS, values);
        setPinnedColumn(newPinned);
    };

    const onChangeFilter = (newFilter) => {
        // unselecting the selected rows when there is a change in filter values
        if (selectedRows.size) { // remove selected rows if there is a valid size
            setSelectedRows(new Set());
        }

        persistData(TRANSACTION_REVIEW_V2_FILTERS, newFilter);
        setFilters(newFilter);
    };

    const rowKeyGetter = (row) => row.order_id; // unique key getter

    /**
     * filterColumnCallback will remove the est_balance as per value of flag "isEstAmountFieldVisible"
     *
     */

    const filterColumnCallback = (field) => (field.key === 'est_balance' ? isEstAmountFieldVisible : true);

    // column object to be rendered in react data grid table
    const columns = React.useMemo(
        () => [
            SelectColumn,
            {
                key: 'credited_debited_at',
                name: 'Transaction Date',
                type: 'date',
                renderCell: DateRenderCell,
                renderEditCell: DateRenderCell,
            },
            {
                key: 'signals',
                name: 'Risk Signal',
                renderCell: renderRiskSignalCell,
                renderEditCell,
            },
            {
                key: 'other_actor_id',
                name: 'Counterparty Actor ID',
                renderEditCell,
            },
            {
                key: 'other_actor_name',
                name: 'Counterparty Actor Name',
                renderCell: (p) => <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]} />,
                renderEditCell: (p) => <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]} />,
            },
            {
                key: 'accounting_entry_type',
                name: 'Debit / Credit',
                renderCell: (p) => <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]} />,
                renderEditCell: (p) => <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]} />,
            },
            {
                key: 'amount',
                name: 'Transaction Amount',
                type: 'number',
                renderCell: (p) => (
                    <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]}>
                        {/** converting to number to show it in a currency format (eg. 123456 -> 1,23,456.00) */}
                        {getCurrencyAmountFormat(p.row[p.column.key])}
                    </ColorFormatCell>
                ),
                renderEditCell: (p) => (
                    <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]}>
                        {/** converting to number to show it in a currency format (eg. 123456 -> 1,23,456.00) */}
                        {getCurrencyAmountFormat(p.row[p.column.key])}
                    </ColorFormatCell>
                ),
            },
            {
                key: 'transaction_status',
                name: 'Transaction Status',
                renderCell: (p) => <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]} />,
                renderEditCell: (p) => <ColorFormatCell {...p} colorCodes={colorCodesDict[p.column.key]} />,
            },
            {
                key: 'payment_protocol',
                name: 'Payment Protocol',
                renderEditCell,
            },
            {
                key: 'provenance',
                name: 'Provenance',
                renderEditCell,
            },
            {
                key: 'money_flow',
                name: 'P2P P2M',
                renderEditCell,
            },
            {
                key: 'merchant_name',
                name: 'Merchant Name',
                renderEditCell,
            },
            {
                key: 'remarks',
                name: 'Transaction Remarks',
                renderEditCell,
            },
            {
                key: 'location',
                name: 'Location',
                renderEditCell,
            },
            {
                key: 'workflow',
                name: 'Order Workflow',
                renderEditCell,
            },
            {
                key: 'error_description',
                name: 'Error Description Calculated',
                renderEditCell,
            },
            {
                key: 'display_category',
                name: 'Display Category',
                renderEditCell,
            },
            {
                key: 'order_id',
                name: 'Transaction ID',
                renderEditCell,
            },
            {
                key: 'tripped_rules',
                name: 'Tripped Rules',
                renderEditCell,
            },
            {
                key: 'est_balance',
                name: 'Proxy Closing Balance',
                renderCell: (cellProps) => ((estimateBalanceToDateMap && cellProps?.row.transaction_id
                     && estimateBalanceToDateMap[cellProps?.row.transaction_id])
                    ? estimateBalanceToDateMap[cellProps?.row.transaction_id] : ''),
            },
            {
                key: 'particulars',
                name: 'Transaction particulars',
                renderEditCell,
            },
            {
                key: 'order_created_at',
                name: 'Order Created at',
                type: 'date',
                renderCell: DateRenderCell,
                renderEditCell: DateRenderCell,
            },
        ].filter(filterColumnCallback).map((item, index) => {
            if (index === 0) {
                return {
                    ...item,
                    headerCellClass: 'select-cell',
                    cellClass: 'select-cell',
                };
            }
            return {
                ...item,
                draggable: true,
                frozen: pinnedColumn.has(item.key),
                width: columnWidths[item.key] ?? item.width ?? COLUMN_WIDTH,
                headerCellClass: 'filter-cell s-rdg-cell',
                cellClass: 's-rdg-cell',
                renderHeaderCell: (p) => (
                    <HeaderFilter {...p} filter={filters} setFilter={onChangeFilter} onPin={handlePin} />
                ),
            };
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filters, handlePin, pinnedColumn, columnWidths],
    );
    const defaultColumnIndexes = columns.map((_, index) => index);

    const [columnsOrder, setColumnsOrder] = useState(() => getPersistedData(TRANSACTION_REVIEW_V2_ORDER, defaultColumnIndexes));
    const reorderedColumns = React.useMemo(() => columnsOrder.map((index) => columns[index]), [columns, columnsOrder]);

    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [sortColumns, setSortColumns] = useState([]);

    const sortedAndFilteredData = React.useMemo(() => multiLevelSort(multiLevelFilter(data, filters), sortColumns), [data, sortColumns, filters]);

    const [contextMenuProps, setContextMenuProps] = useState(null);

    const isContextMenuOpen = contextMenuProps !== null;

    const [selectedRowData, setSelectedRowsData] = useState({});

    const handleOnCellContextMenu = (args, event) => {
        event.preventGridDefault();
        // Do not show the default context menu
        event.preventDefault();
        setContextMenuProps({
            row: args.row,
            column: args.column,
            isSelected: selectedRows.has(args.row.order_id),
            top: event.pageY,
            left: event.pageX,
        });
    };

    const onContextMenuClose = () => {
        setContextMenuProps(null);
    };

    // useEffect to reset isClear after a delay
    useEffect(() => {
        if (isClear) {
            setTimeout(() => {
                setClear(false);
            }, 500);
        }
    }, [isClear]);

    // function to clear column widths in state and localstorage
    const clearColumnWidth = () => {
        clearPersistedData(TRANSACTION_REVIEW_V2_COLUMN_WIDTH);
        if (!isNilOrEmpty(columnWidths)) {
            setClear(true);
        }
        setColumnWidths({});
    };

    // hanlder to clear transaction table data like filters,column size, column order ect..
    const handleClear = (option) => {
        // clear column widths
        if (option.value === COLWIDTH || option.value === ALL) {
            clearColumnWidth();
        }

        // clear re-ordered columns
        if (option.value === ORDERS || option.value === ALL) {
            clearPersistedData(TRANSACTION_REVIEW_V2_ORDER);
            setColumnsOrder(defaultColumnIndexes);
        }

        // clear filters
        if (option.value === FILTERS || option.value === ALL) {
            clearPersistedData(TRANSACTION_REVIEW_V2_FILTERS);
            setFilters({});
        }

        // clear pinned columns
        if (option.value === PINS || option.value === ALL) {
            clearPersistedData(TRANSACTION_REVIEW_V2_PINS);
            setPinnedColumn(new Set(defaultPinnedColumns));
        }

        // clear selected rows
        if (option.value === SELECTEDROWS || option.value === ALL) {
            setSelectedRows(new Set());
        }
    };

    const onSortColumnsChange = (sortCols) => {
        setSortColumns(sortCols);
    };

    // column Reorder handler
    const onColumnsReorder = (sourceKey, targetKey) => {
        setColumnsOrder((cOrder) => {
            // find index of source column to be reorderer
            const sourceColumnOrderIndex = cOrder.findIndex(
                (index) => columns[index].key === sourceKey,
            );
            // find index of target column
            const targetColumnOrderIndex = cOrder.findIndex(
                (index) => columns[index].key === targetKey,
            );
            // get sourceColumnOrder value
            const sourceColumnOrder = cOrder[sourceColumnOrderIndex];
            // remove sourceColumnOrder value
            const newColumnsOrder = cOrder.toSpliced(sourceColumnOrderIndex, 1);
            // place the sourceColumnOrder infront of targetColumnOrder
            newColumnsOrder.splice(targetColumnOrderIndex, 0, sourceColumnOrder);
            persistData(TRANSACTION_REVIEW_V2_ORDER, newColumnsOrder);
            return newColumnsOrder;
        });
    };

    // function to select multple rows on dragging.
    // n numbers of calls will be made if n numbers of rows selected through dragging
    const handleFill = ({ sourceRow, targetRow }) => {
        setSelectedRows((pre) => {
            const newSet = new Set(pre);
            newSet.add(rowKeyGetter(targetRow));

            if (!newSet.has(rowKeyGetter(sourceRow))) {
                newSet.add(rowKeyGetter(sourceRow));
            }

            return newSet;
        });

        return targetRow;
    };

    const handleOnResize = debounce((index, width) => {
        // array of pinned columns
        const pinnedCols = reorderedColumns.filter((item) => !!item.frozen);
        // array of unpinned columns
        const unPinnedCols = reorderedColumns.filter((item) => !item.frozen);
        // concat pinned columns and unpinned columns. pinned columns should take the starting postions
        const newCols = [...pinnedCols, ...unPinnedCols];
        // get columnKey based on index
        const columnKey = newCols[index].key;

        if (columnKey) {
            const colWidths = { ...columnWidths };
            colWidths[columnKey] = width; // assign width to the column key in columnWidths
            persistData(TRANSACTION_REVIEW_V2_COLUMN_WIDTH, colWidths);
            setColumnWidths(colWidths);
        }
    }, 1000);

    // Amount aggregation returns either aggregated object or null
    const amountAggregation = useMemo(() => {
        let total = 0;
        let min = Infinity;
        let max = -Infinity;
        const uniqueCounterPartyActors = new Set();

        if (selectedRows.size) {
            selectedRows.forEach((value) => {
                // find selected row data
                const foundItem = data.find((item) => item.order_id === value);
                if (foundItem) {
                    const amount = Number(foundItem.amount);
                    total += Number(amount); // calcualte sum total
                    min = Math.min(min, amount); // calculate min value
                    max = Math.max(max, amount); // calculate max value

                    if (foundItem.other_actor_id) { // check for valid other_actor_id
                        uniqueCounterPartyActors.add(foundItem.other_actor_id);// add unique counter party actors
                    }
                }
            });

            const avg = (total / selectedRows.size).toFixed(2); // calculate avg

            return {
                total: getCurrencyAmountFormat(total),
                min: getCurrencyAmountFormat(min),
                max: getCurrencyAmountFormat(max),
                avg: getCurrencyAmountFormat(avg),
                uniqueOtherActorCount: getCurrencyAmountFormat(uniqueCounterPartyActors.size, 0),
            };
        }

        return null;
    }, [selectedRows, data]);

    useEffect(() => {
        const rowDataMap = {};
        if (selectedRows.size) {
            selectedRows.forEach((value) => {
                const foundItem = data.find((item) => item.order_id === value);
                if (foundItem) rowDataMap[value] = foundItem;
            });
        }
        setSelectedRowsData(rowDataMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, data]);

    const handleRiskSignalOnClick = (rule) => {
        const newFilter = { ...filters };
        newFilter.signals = rule;
        onChangeFilter(newFilter);
    };

    return (
        <div className={containerClasses}>
            <div className='rdgrid-tt-mw'>
                <div className='rdgrid-tt-mwc'>
                    { riskSignalRules.length > 0 && riskSignalRules[0].length > 0
                    && (
                        <div className='rdgrid-signal-list-container'>
                            <span className='rdgrid-signal-list-title'><strong>Risk Signals: </strong></span>
                            {riskSignalRules && [...new Set(riskSignalRules.flat())].sort().map((rule) => {
                                if (rule) {
                                    return (
                                        <span
                                            key={rule}
                                            role='presentation'
                                            className='rdgrid-signal-list-item'
                                            onClick={() => { handleRiskSignalOnClick(rule); }}
                                        >
                                            {rule}
                                        </span>
                                    );
                                }
                                return null;
                            })}
                            <span
                                role='presentation'
                                className='rdgrid-signal-list-item rdgrid-signal-list-item-x'
                                onClick={() => { handleRiskSignalOnClick(''); }}
                            >
                                X
                            </span>
                        </div>
                    )}
                    <div>
                        <div className='rdgrid-loader-wrapper mr-16'>
                            <strong>Total Txn Count - {`${getCurrencyAmountFormat(count, 0)} `} </strong>
                            {
                                fetching
                                    ? <div className='loader' />
                                    : null
                            }
                        </div>
                        {
                            count !== sortedAndFilteredData.length
                                ? <strong className='mr-16'>Filter Count - {`${getCurrencyAmountFormat(sortedAndFilteredData.length, 0)} `} </strong>
                                : null
                        }
                        {
                            selectedRows.size
                                ? (
                                    <strong className='mr-16'>Select Count - {`${getCurrencyAmountFormat(selectedRows.size, 0)} `} </strong>
                                )
                                : null
                        }
                    </div>
                    <div>
                        {
                            amountAggregation
                                ? (
                                    <strong className='mr-16 rdgrid-tt-agg'>
                                        <span className='mr-16'>Amt Summary - </span>
                                        <span className='mr-16'>( Total : {`${amountAggregation.total} `}</span>
                                        <span className='mr-16'>Avg : {`${amountAggregation.avg} `}</span>
                                        <span className='mr-16'>Min : {`${amountAggregation.min} `}</span>
                                        <span className='mr-16'>Max : {`${amountAggregation.max} `})</span>
                                    </strong>
                                ) : null
                        }
                    </div>
                    <div>
                        {
                            amountAggregation
                                ? (
                                    <strong className='mr-16'>
                                        <span className='mr-16'>Unique Counterparty Count :
                                            {`${getCurrencyAmountFormat(amountAggregation.uniqueOtherActorCount, 0)} `}
                                        </span>
                                    </strong>
                                ) : null
                        }
                    </div>
                </div>
                <div className='rdgrid-cd mr-16'>
                    <ClearDropDown onOptionClick={handleClear} />
                </div>
                <Button
                    v2
                    type='button'
                    primary
                    label={label}
                    onClick={handleMode}
                />
                <TransactionAnnotationModal
                    selectedRows={Object.values(selectedRowData)}
                />
            </div>
            {
                !isClear
                    ? (
                        <DataGrid
                            columns={reorderedColumns}
                            rows={sortedAndFilteredData}
                            sortColumns={sortColumns}
                            headerRowHeight={70}
                            rowKeyGetter={rowKeyGetter}
                            className='rdg-light rdgrid-fc'
                            selectedRows={selectedRows}
                            onFill={handleFill}
                            onSelectedRowsChange={setSelectedRows}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                                width: COLUMN_WIDTH,
                                minWidth: COLUMN_MIN_WIDTH,
                            }}
                            onColumnResize={handleOnResize}
                            onColumnsReorder={onColumnsReorder}
                            onSortColumnsChange={onSortColumnsChange}
                            onCellContextMenu={handleOnCellContextMenu}
                            renderers={{ renderRow }}
                            enableVirtualization
                        />
                    )
                    : <Loader visible />
            }
            {isContextMenuOpen
        && createPortal(
            <ContextMenu
                top={contextMenuProps.top}
                filters={filters}
                left={contextMenuProps.left}
                row={contextMenuProps.row}
                column={contextMenuProps.column}
                isSelected={contextMenuProps.isSelected}
                onChangeFilter={onChangeFilter}
                setSelectedRows={setSelectedRows}
                onClose={onContextMenuClose}
            />,
            document.body,
        )}
        </div>
    );
};

export default React.memo(TransactionTable);
