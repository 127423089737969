import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Table } from '../../../../components';
import { selectVkycData, selectCurrentSubStage, selectCurrentStage, selectMeetingLoadingState } from '../../selectors';
import {
    getCaptureScreenshot, getExtractDocumentDetails, getMatchScore, getPerformClientAction, setCurrentStagePointers,
} from '../../actions';
import {
    CAMERA_ORIENTATION, CLIENT_ACTION, ONBOARIDNG_STAGE_TYPE, VKYCDocumentType, stagePointerPayload,
} from '../../constants';
// eslint-disable-next-line no-unused-vars
import ProgressBar from '../../../../components/ProgressBar';
import { dobFormatter, nameFormatter, matchScoreFormatting } from '../../utils/serverCodeFormatter';
import ImageUtil, { AutoScaleImageViewer } from '../../../../utils/imageUtils';

const PANCardStep = (props) => {
    const { data, meetingId, setAllAnsweredYes } = props;

    const [isImageClear, setIsImageClear] = useState();
    const [imageOrientations, setImageOrientations] = useState({});
    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData, shallowEqual);

    const subStageStep = useSelector(selectCurrentSubStage(meetingId));
    const currentStepNumber = useSelector(selectCurrentStage(meetingId));
    const meetingLoadingState = useSelector(selectMeetingLoadingState(meetingId));

    const setSubStageStep = (stepNumber) => {
        dispatch(setCurrentStagePointers(stagePointerPayload(meetingId, currentStepNumber, stepNumber)));
    };

    const captureOnClick = () => {
        const imageUrl = vkycData?.meetings?.[meetingId]?.screenshot?.imageUrl;
        if (imageUrl) {
            dispatch(getCaptureScreenshot({
                meetingId,
                screenKey: ['meetings', meetingId, 'stages',
                    [vkycData?.meetings?.[meetingId]?.stepNumber], data?.stages[subStageStep]?.image_capture?.camera_type],
            }));
        }
        dispatch(getCaptureScreenshot({
            meetingId,
            screenKey: ['meetings', meetingId, 'stages',
                [vkycData?.meetings?.[meetingId]?.stepNumber], data?.stages[subStageStep]?.image_capture?.camera_type],
            data: {},
        }));
    };

    const switchCamera = (currentOrientation) => () => {
        dispatch(getPerformClientAction({
            meetingId: vkycData?.data?.meetingId, clientAction: CLIENT_ACTION.Flip, currentOrientation, screenKey: 'action',
        }));
    };

    const handleFaceMatchSubmit = (answer) => {
        if (answer) {
            setSubStageStep(subStageStep + 1);
        }
    };

    const switchCameraAndContinue = () => {
        const currentOrientation = vkycData?.meetings?.[meetingId]?.cameraOrientation;
        if (currentOrientation === CAMERA_ORIENTATION.back) {
            switchCamera(CAMERA_ORIENTATION.back)();
        }
    };

    const onClickImageClearYes = () => {
        setIsImageClear(true);
        setSubStageStep(subStageStep + 1);
        const stepNumber = vkycData?.meetings?.[meetingId]?.stepNumber;
        const imageObject = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber]?.[CAMERA_ORIENTATION.back];
        dispatch(getExtractDocumentDetails({
            documentType: VKYCDocumentType.PAN,
            imageUrl: imageObject?.imageUrl,
            frontImageIdentifier: imageObject?.imageIdentifier,
            extractFaceImage: true,
            screenKey: ['meetings', meetingId, 'stages', vkycData?.meetings?.[meetingId]?.stepNumber, 'documentDetails'],
            meetingId,
        }));
        switchCameraAndContinue();
    };

    const getOrientationClass = (url) => {
        if (!url) return false;

        const img = new Image();
        img.src = url;
        img.onload = () => {
            const isHorizontal = img.width > img.height;
            setImageOrientations((prev) => ({ ...prev, [url]: isHorizontal }));
        };

        return imageOrientations[url];
    };

    const currentStepGlobal = data?.stages?.[subStageStep];
    const stepNumberGlobal = vkycData?.meetings?.[meetingId]?.stepNumber;
    const currentStageGlobal = vkycData?.meetings?.[meetingId]?.stages?.[stepNumberGlobal];
    const imgUrlGlobal = currentStageGlobal?.[currentStepGlobal?.image_capture?.camera_type]?.imageUrl;
    const documentImageGlobal = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages?.[stepNumberGlobal]?.[CAMERA_ORIENTATION.back];

    useEffect(() => {
        getOrientationClass(imgUrlGlobal);
        getOrientationClass(documentImageGlobal?.imageUrl);
    }, [imgUrlGlobal, documentImageGlobal?.imageUrl]);

    const getNextComponent = () => {
        const currentStep = data?.stages?.[subStageStep];
        const stepNumber = vkycData?.meetings?.[meetingId]?.stepNumber;

        const currentStage = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber];

        const imgUrl = currentStage?.[currentStep?.image_capture?.camera_type]?.imageUrl;

        const documentDetails = currentStage?.documentDetails;

        const documentImage = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages?.[stepNumber]?.[CAMERA_ORIENTATION.back];

        const faceImage = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages?.[2]?.[CAMERA_ORIENTATION.front];

        switch (currentStep?.stage_type) {
            case ONBOARIDNG_STAGE_TYPE.IMAGE_CAPTURE:
                if (vkycData?.meetings?.[meetingId]?.cameraOrientation !== currentStep?.image_capture?.camera_type) {
                    return (
                        <div className='vkyc-switch-container'>
                            <div className='vkyc-container-title mb-30'>{currentStep?.image_capture?.instruction}</div>
                            <Button
                                v2
                                primary
                                label='Switch Camera Side'
                                extClasses=''
                                onClick={switchCamera(vkycData?.meetings?.[meetingId]?.cameraOrientation)}
                            />
                        </div>
                    );
                }
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{imgUrl
                            ? currentStep?.image_capture?.post_capture_instruction
                            : currentStep?.image_capture?.instruction}
                        </div>
                        <div className='vkyc-image-container'>
                            {/* ToDo(Hrishabh) Making a function for following class retrieval and refactoring code */}
                            <AutoScaleImageViewer
                                url={imgUrl}
                                captureOnClick={captureOnClick}
                                baseContainerClass='vkyc-image-placeholder'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal vkyc-image-placeholder-hori-button-height'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            {imgUrl && (
                                <div className='ml-30'>
                                    <div className='sop-step-des'>{currentStep?.image_capture?.post_capture_detailed_instruction}</div>
                                    <div className='frWrapper'>
                                        <Button
                                            primary={isImageClear}
                                            secondary={!isImageClear}
                                            v2
                                            label='Yes'
                                            onClick={onClickImageClearYes}
                                            extClasses='vkyc-step-option'
                                        />
                                        <Button
                                            primary={isImageClear === false}
                                            secondary={isImageClear !== false}
                                            v2
                                            label='No'
                                            onClick={() => { setIsImageClear(false); }}
                                            extClasses='vkyc-step-option'
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );

            case ONBOARIDNG_STAGE_TYPE.FACE_MATCH: {
                const matchScorePercentage = currentStage?.matchScore?.face_image_match_result?.match_score_percent;

                return (
                    <div className='vkyc-image-container'>
                        <AutoScaleImageViewer
                            url={documentImage?.imageUrl}
                            baseContainerClass='vkyc-image-placeholder mr-30'
                            baseImageClass='vkyc-image'
                            landscapeContainerClass='vkyc-image-placeholder-horizontal'
                            landscapeImageClass='vkyc-image-horizontal'
                        />
                        <div className='vkyc-image-placeholder'>
                            <ImageUtil url={faceImage?.imageUrl} containerClass='vkyc-image' />
                        </div>
                        <div className='ml-30'>
                            <div className='mb-30'>
                                <div className='sop-step-des'>Match Score</div>
                                {
                                    !meetingLoadingState && (
                                        <div className='vkyc-match-score-container'>
                                            <div className={`vkyc-match-score-percentage
                                ${matchScorePercentage >= 50
                                            ? 'vkyc-match-score-percentage--green' : 'vkyc-match-score-percentage--red'}
                                }`}
                                            >
                                                {matchScoreFormatting(matchScorePercentage)}%
                                            </div>
                                            <div className='vkyc-match-score-progress'>
                                                <ProgressBar
                                                    number={matchScorePercentage || 0}
                                                    progressBarStyleClassName={
                                                        matchScorePercentage >= 50
                                                            ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='sop-step-des'>{currentStep?.face_match?.description}</div>
                            <div className='frWrapper'>
                                <Button
                                    primary
                                    v2
                                    label='Yes'
                                    onClick={() => {
                                        setAllAnsweredYes(true);
                                        handleFaceMatchSubmit(true);
                                    }}
                                    extClasses='vkyc-step-option'
                                />
                                <Button
                                    secondary
                                    v2
                                    label='Retake'
                                    onClick={() => {
                                        handleFaceMatchSubmit(false);
                                        setSubStageStep(0);
                                    }}
                                    extClasses='vkyc-step-option'
                                />
                            </div>
                        </div>
                    </div>
                );
            }

            case ONBOARIDNG_STAGE_TYPE.OCR_EXTRACTION:
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.ocr_extraction?.instruction}</div>
                        <div className='vkyc-image-container'>
                            <AutoScaleImageViewer
                                url={documentImage?.imageUrl}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            <div className='ml-30'>
                                {
                                    !meetingLoadingState && (
                                        <div className=''>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Name</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.pan_details?.name
                                             && nameFormatter(documentDetails?.document_details?.pan_details?.name)}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Date of birth</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.pan_details?.date_of_birth
                                                && dobFormatter(documentDetails?.document_details?.pan_details?.date_of_birth)}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Parent’s Name</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.pan_details?.guardian_info?.guardian_name
                                             && nameFormatter(documentDetails?.document_details?.pan_details?.guardian_info?.guardian_name)}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>PAN Number</div>
                                                <div className='vkyc-kv-value'>{documentDetails?.document_details?.pan_details?.id}</div>
                                            </div>
                                        </div>
                                    )
                                }

                                <div className='frWrapper'>
                                    <Button
                                        primary
                                        v2
                                        label='Continue'
                                        onClick={() => {
                                            dispatch(getMatchScore({
                                                livenessImageIdentifier: faceImage?.imageIdentifier,
                                                documentImageIdentifier: documentImage?.imageIdentifier,
                                                meetingId,
                                                documentDetails: documentDetails?.document_details,
                                                screenKey: [
                                                    'meetings',
                                                    meetingId,
                                                    'stages',
                                                    vkycData?.meetings?.[meetingId]?.stepNumber,
                                                    'matchScore',
                                                ],
                                            }));
                                            setSubStageStep(subStageStep + 1);
                                        }}
                                        extClasses='vkyc-step-option'
                                    />
                                    <Button
                                        primary
                                        v2
                                        label='Retake'
                                        onClick={() => {
                                            setSubStageStep(subStageStep - 1);
                                        }}
                                        extClasses='vkyc-step-option'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ONBOARIDNG_STAGE_TYPE.OCR_VALIDATION:
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.ocr_validation?.instruction}</div>
                        <div className=''>
                            <Table
                                rowDataVersion={2}
                                objKey='header_key'
                                labelData={currentStage?.matchScore?.ocr_details_match_results?.columnData}
                                contentData={currentStage?.matchScore?.ocr_details_match_results?.rowData}
                                // renderActionableColumn={renderActions}
                                prevToken={null}
                                nextToken={null}
                                paginatedClickHandler={() => {}}
                                extClasses={{
                                    container: 'p-20',
                                }}
                            />
                        </div>
                    </div>
                );

            default: return <div>Something went wrong</div>;
        }
    };

    return (
        <div>
            {getNextComponent()}
        </div>
    );
};

export default PANCardStep;
